import React from 'react';
import { Divider } from "@material-ui/core";
import { prettyTime } from '../Utils/prettyTime';
import { StoreContext } from '../Hooks/store';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tcentries: {
        backgroundColor: '#fff',
    },
    tcrow: {
        display: 'table',
    },
    tcleft: {
        fontSize: '12pt',
        fontWeight: '500',
        textAlign: 'left',
        margin: '0 5px 0 15px',
        width: '125px',
        display: 'inline-block',
        borderRight: 'solid 1px #ccc',
    },
    tcright: {
        fontSize: '12pt',
        fontWeight: '500',
        textAlign: 'left',
        marginLeft: '5px',
    },
    acright: {
        fontSize: '12pt',
        fontWeight: '500',
        textAlign: 'left',
        marginLeft: '5px',
        display: 'table-cell',
        verticalAlign: 'top',
    },
    activitylengths: {
        fontFamily: 'Montserrat',
        fontSize: '14pt',
        fontWeight: '700',
        textAlign: 'center',
        marginLeft: '5px',
        color: '#000'
    },
}));

export function CompletedActivities() {
    const classes = useStyles();
    const { activitiescustom, activitiesselected } = React.useContext(StoreContext);
    const [donecustom] = activitiescustom;
    const [doneselect] = activitiesselected;

    const completedItems = [...donecustom, ...doneselect];
    completedItems.sort((a, b) => b.utc_end - a.utc_end); // sort descending
    const doneCount = completedItems.length;

    const ActivitiesHeader = React.memo(() => (
        <div className={classes.tcentries}>
            <div>
                <span className={classes.tcleft}><strong>Activity</strong></span>
                <span className={classes.tcright}><strong>Duration</strong></span>
            </div>
            <Divider />
        </div>
    ));

    const CompletedItems = React.memo(() => (
        <>
            <div className={classes.activitylengths}>Completed Activities... ({doneCount})</div>
            <Divider />
            <div className={classes.tcentries}>
                <ActivitiesHeader />
                {completedItems.map((i) => {
                    return (
                        <>
                            <div className={classes.tcrow}>
                                <span className={classes.tcleft}>{`${i.activity}`}</span>
                                <span className={classes.acright}>{`${i.local_start}-${i.local_end} (${prettyTime(1000 * i.elapsed)})`}</span>
                            </div>
                            <Divider />
                        </>
                    );
                })}
            </div>
        </>
    ));
    return CompletedItems;
}
