import React, { useState, useEffect } from 'react';
import { StoreContext } from '../Hooks/store';
import useTimer from '../Hooks/useTimer';
import { localTime } from '../Utils/localTime';
import { pad } from '../Utils/prettyTime';

import * as yaySound from './yay.mp3';

import styled from 'styled-components';
export const InputText = styled.input.attrs({
  type: 'text',
})`
  padding: 1px 2px; 
  font-size: 10pt;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  width: 120px;
`;
//https://github.com/zdenulo/firestore2bigquery/blob/master/generate_data.py

export default function ExerciseTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
    } = useTimer({ expiryTimestamp, onExpire: () => shoutYay() });
  //} = useTimer({ expiryTimestamp, onExpire: () => testShoutYay() });

  const { exercisestore } = React.useContext(StoreContext);
  const [doneworkout, setDoneworkout] = exercisestore;
  const [activeStep, setActiveStep] = useState(-1);
  const [activeRound, setActiveRound] = useState(1);
  const [restSeconds] = useState(5);
  const [started, setStarted] = useState(false);
  const [done, setDone] = useState(false);
  const [isResting, setIsResting] = useState(false);
  //const [customtxt, setCustomtxt] = useStickyState('', 'custom' + hashCode('custom' + props.activity));
  const [count, setCount] = useState(0);
  const [steps] = useState([//TODO 
    'Jumping Jacks',
    'Sit-ups',
    'Elbow Planks',
    'Push-ups',
  ]);

  const [rounds] = useState(2); // TODO
  const [testcycle, setTestcycle] = useState(1);

  const audio = new Audio(yaySound)

  function testMain() {
    testShoutYay();
  }
  const testShoutYay = () => {
    // if (activeStep === 1 ) {
    //   audio.play();
    // } else {
    //   audio.src = yaySound;
    //   audio.play()
    // }

    if (testcycle === 1) {
      audio.src = yaySound;
      audio.play()
    }

    if (testcycle === 2) {
      audio.src = yaySound;
      audio.play()
    }

    if (testcycle === 3) {
      audio.src = yaySound;
      audio.play()
    }

    if (testcycle === 4) {
      audio.src = yaySound;
      audio.play()
    }

    console.log(`finished run #${testcycle}`);
    
    if (testcycle < 11) {
      setTestcycle(testcycle + 1)
      TestTimer(5) //recurse
    } else {
      setTestcycle(1); //reset
      console.log('all done!')
    }
    

  }
  function TestTimer(seconds) {
    const t = new Date();
    t.setSeconds(t.getSeconds() + seconds);
    restart(t);
  }

  const shoutYay = () => {
    const counter = steps.length - 1;// TODO: no of rounds * no of exercises
    if (!isResting) {
      // skip when resting
      setActiveStep(activeStep + 1)

      if (activeStep === 1) {
        audio.play();
      } else {
        audio.src = yaySound;
        audio.play()
      }
  
    }

    setIsResting(!isResting); // toggle

    if (activeStep > counter) {
      setActiveRound(activeRound + 1);
      if (activeRound === rounds) {
        setDone(true);
        setStarted(false);
        return;
      } else {
        setActiveStep(0);
      }
    }

    RestartTimer(10)
  }

  function sleep(ms) {
    //https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function RestartTimer(seconds) {
    if (!isResting) {
      seconds = restSeconds;
    }

    //await sleep(2000);

    const counter = steps.length - 1;// TODO: no of rounds * no of exercises

    const t = new Date();
    t.setSeconds(t.getSeconds() + seconds);
    restart(t);
  }

  function handleOnChange(e) {
    setCount(e.target.value);
  }

  function saveCount() {
    //resume();
    const objArr = makeTempArray(count);
    setDoneworkout([...objArr, ...doneworkout]);
  }

  function makeTempArray(cnt) {
    const temp = [];
    const newObj = {
      exercise: steps[activeStep - 1],
      count: cnt,
      utc_logtime: Date.now(),
      local_logtime: localTime(Date.now()),
    };
    temp.push(newObj);
    return temp;
  }


  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        {isRunning && !isResting &&
          <span>{steps[activeStep]}</span>
        }
        {isResting && !done &&
          <span>Resting...</span>
        }
      </div>
      <div>
        {isResting && !done &&
          <>
            <span>Count: </span>
            <InputText maxLength="2" onChange={handleOnChange} />
            <button onClick={() => saveCount()}>Save</button>
          </>
        }
      </div>
      <div style={{ fontSize: '40px' }}>
        <span>{minutes}</span>:<span>{pad(seconds)}</span>
      </div>

      {!started &&
        <button onClick={() => {
          setStarted(true);
          setDone(false);
          setActiveStep(0);
          setActiveRound(1);
          setIsResting(false);
          RestartTimer(10);
          setDoneworkout([]);

          // const t = new Date();
          // t.setSeconds(t.getSeconds() + 10);
          // restart(t);
        }}>Start</button>
      }
      {started && !done &&
        <>
          <button onClick={pause}>Pause</button>
          <button onClick={resume}>Resume</button>
        </>
      }
      {done &&
        <>
          <div>All done!</div>
          <div>
            {doneworkout.map((e) => {
              return (
                <div>
                  <span>{e.exercise}</span>
                  <span>{e.count}</span>
                </div>
              );
            })}
          </div>
        </>
      }
      <div>
        <button onClick={testMain}>Test</button>
        <div>current step # {testcycle}</div>
      </div>

    </div >
  );


}