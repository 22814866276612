import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Divider } from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from "@material-ui/core/styles";

import WatchOutlinedIcon from '@material-ui/icons/WatchOutlined';

import { notify } from "../Notifications";
import CurrentDate from '../Utils/CurrentDate';
import ActivityTimer from './ActivityTimer';
import { StoreContext } from '../Hooks/store';
import { CompletedActivities } from './CompletedActivities';
import { addToStringArray, sortStringArray } from '../Utils/arrayUtils';
import { CancelBtn, SaveBtn, InputText } from './ActivityTimer.styled';
import useStickyState from '../Hooks/useStickyState';


const useStyles = makeStyles((theme) => ({
    iconwidth: {
        minWidth: '35px !important',
    },
    menuwidth: {
        width: 'inherit'
    },

    menuDiv: { marginLeft: '10px' },
    activitiesMenu: {
        fontSize: '12pt', 
        verticalAlign: 'middle',
        marginBottom: '5px',
        display: 'inline-block'
    },

    tctitle: {
        fontFamily: 'Montserrat', fontSize: '11pt',
        backgroundColor: '#00416e', color: '#fff',
        textAlign: 'center'
    },
    b1: {
        backgroundColor: '#fff',
        minWidth: '320px',
        marginLeft: '-5px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function Homework(props) {

    const {
        modaldialog,
        activitiesclockedin,
        activitiesaddvisible } = React.useContext(StoreContext);
    const [clockedin] = activitiesclockedin;
    const [, setLoadedmodal] = modaldialog;
    const [showaddactivity, setShowaddactivity] = activitiesaddvisible;

    const [, setHomeworkopen] = useState(props.show)
    const [menucolor] = useState(props.rendercolor)

    const [newChoiceTxt, setNewChoiceTxt] = useState('')
    const [choices, setChoices] = useStickyState(['', 'Break', 'Lunch', 'Piano',
        'Math', 'DreamBox', 'Math-Packet', 'Math-IXL',
        'Reading', 'Comprehension', 'Writing',
        'Science', 'Walk', 'Workout'], 'homeworkchoices');

    choices.sort(sortStringArray); //choices.sort(sortStringArray).reverse();

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const classes = useStyles();

    //let homeworkicon = useViewport > 620 ? <WatchOutlinedIcon color="secondary" /> : <WatchOutlinedIcon style={{color: '#fff'}} />
    let homeworkicon = menucolor === 'primary' ? <WatchOutlinedIcon color="primary" /> : <WatchOutlinedIcon style={{ color: menucolor }} />

    const [open, setOpen] = useState(false);

    function handleOnClose() {
        setLoadedmodal("")
        setOpen(false)
        setHomeworkopen(false)
        //setSessionid('')
    }

    function handleOnOpen() {
        setLoadedmodal("Homework")
        setOpen(true)
    }



    function addChoice() {
        setShowaddactivity(false);
        if (choices.includes(newChoiceTxt)) {
            notify(`Won't add duplicate: ${newChoiceTxt}`);
            return;
        }

        //let mutatedChoices = choices => [...choices, newChoiceTxt];
        //setChoices(mutatedChoices)
        //let b = [...choices, newChoiceTxt];
        setChoices(addToStringArray(choices, newChoiceTxt).sort(sortStringArray));
        notify(`Added ${newChoiceTxt}`);
    }

    function cancelAddChoice() {
        setShowaddactivity(false);
        setNewChoiceTxt('');
    }

    function handleOnChange(e) {
        setNewChoiceTxt(e.target.value);
    }

    // merge the two object arrays
    const AllCompletedActivities = CompletedActivities();

    const AddActivity =
        <>
            <InputText
                maxLength="15"
                onChange={handleOnChange}
                placeholder='Enter activity...'
                onMouseEnter={() => {
                    if (newChoiceTxt === 'Enter activity...') {
                        setNewChoiceTxt('');
                    }
                }}
            ></InputText>

            <CancelBtn
                inputColor='#333'
                onClick={() => cancelAddChoice()}>
                Cancel
        </CancelBtn>

            <SaveBtn
                inputColor='#0080BB'
                onClick={() => addChoice()}>
                Save
        </SaveBtn>

        </>
        ;

    return (
        <>
            <div className={classes.menuDiv} onClick={handleOnOpen}>
                {homeworkicon}
                <span className={classes.activitiesMenu}>&nbsp;&nbsp;Activities</span>
            </div>

            <Dialog open={open} onClose={handleOnClose}>
                <DialogTitle className={classes.tctitle} onClose={handleOnClose}>
                    <CurrentDate options={dateOptions} />
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleOnClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.b1}>
                    <div><strong><ActivityTimer color='#0080BB' activity='Begin/End' /></strong></div>
                    {!clockedin &&
                        <><label><strong><i>Please Begin to get started</i></strong></label></>
                    }
                    <Divider />
                    <div><ActivityTimer color='orange' activity='Select' placeholder='Select activity:' options={choices} /></div>
                    <div>
                        {showaddactivity &&
                            AddActivity
                        }
                    </div>
                    <div><ActivityTimer color='green' activity='Custom' placeholder='' /></div>
                    <Divider />
                    {/* 
                            <div><ActivityTimer color='purple' activity='Break' /></div>
                            <Divider />
                            <div><ActivityTimer color='#8CC63F' activity='Math' /></div>
                            <div><ActivityTimer color='#0080BB' activity='DreamBox' /></div>
                            <div><ActivityTimer color='purple' activity='Packet' /></div>
                            <div><ActivityTimer color='#FF1D25' activity='IXL' /></div>
                            <Divider />
                            <div><ActivityTimer color='#0080BB' activity='Lunch' /></div>
                            <div><ActivityTimer color='#8CC63F' activity='Play' /></div>
                            <Divider />
                            <div><ActivityTimer color='purple' activity='Reading' /></div>
                            <div><ActivityTimer color='#0080BB' activity='Comprehension' /></div>
                            <div><ActivityTimer color='#8CC63F' activity='Writing' /></div>
                            <Divider />
                            <div><ActivityTimer color='#0080BB' activity='Science' /></div>
                            <Divider />
                            <div><ActivityTimer color='purple' activity='Piano' /></div>
                            <Divider />
                            <div><ActivityTimer color='#FF1D25' activity='Clock In/Out' /></div> */}

                    <AllCompletedActivities />
                </DialogContent>

            </Dialog>
        </>
    );
}

