import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Divider } from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from "@material-ui/core/styles";

import WatchOutlinedIcon from '@material-ui/icons/WatchOutlined';

import CurrentDate from '../Utils/CurrentDate';
import { StoreContext } from '../Hooks/store';

import ExerciseTimer from './ExerciseTimer';
//https://github.com/zdenulo/firestore2bigquery/blob/master/generate_data.py
const useStyles = makeStyles((theme) => ({
    iconwidth: {
        minWidth: '35px !important',
    },
    menuwidth: {
        width: 'inherit'
    },

    menuDiv: { marginLeft: '10px' },
    activitiesMenu: {
        fontSize: '12pt', 
        verticalAlign: 'middle',
        marginBottom: '5px',
        display: 'inline-block'
    },

    tctitle: {
        fontFamily: 'Montserrat', fontSize: '11pt',
        backgroundColor: '#00416e', color: '#fff',
        textAlign: 'center'
    },
    b1: {
        backgroundColor: '#fff',
        minWidth: '320px',
        marginLeft: '-5px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function Workout(props) {

    const {
        modaldialog } = React.useContext(StoreContext);

    const [, setLoadedmodal] = modaldialog;


    const [menucolor] = useState(props.rendercolor)

    

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const classes = useStyles();

    let workouticon = menucolor === 'primary' ? <WatchOutlinedIcon color="primary" /> : <WatchOutlinedIcon style={{ color: menucolor }} />

    const [open, setOpen] = useState(false);

    function handleOnClose() {
        setLoadedmodal("")
        setOpen(false)
    }

    function handleOnOpen() {
        setLoadedmodal("Homework")
        setOpen(true)
    }

    return (
        <>
            <div className={classes.menuDiv} onClick={handleOnOpen}>
                {workouticon}
                <span className={classes.activitiesMenu}>&nbsp;&nbsp;Workout</span>
            </div>

            <Dialog open={open} onClose={handleOnClose}>
                <DialogTitle className={classes.tctitle} onClose={handleOnClose}>
                    <CurrentDate options={dateOptions} />
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleOnClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.b1}>
                    <div><ExerciseTimer /></div>
                    
                    <Divider />
                    

                </DialogContent>

            </Dialog>
        </>
    );
}

