import React from 'react'
import ReactDOM from 'react-dom'
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import App from './components/App'

//ReactDOM.render(<App />, document.getElementById('root'))

const testAccessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MTYwNDE2OTQsImV4cCI6MTY0NzU3NzY5NCwiYXVkIjoiMTkyLjE2OC42OC4xMjI6NDAwMSIsInN1YiI6ImdrQHRlc3QuY29tIiwiR2l2ZW5OYW1lIjoiR0siLCJTdXJuYW1lIjoiR29kYW1uIiwiRW1haWwiOiJna0B0ZXN0LmNvbSIsIlJvbGUiOiJNYW5hZ2VyIn0.Ow2fzjcDKftUO_kJqA0fMVJHAKPhGNOH0Gz6jLMKud8'
// http://jwtbuilder.jamiekurtz.com
// https://grandstack.io/docs/neo4j-graphql-js-middleware-authorization/
// https://neo4j.com/videos/graphql-authorization-with-auth0-building-a-real-estate-search-app-w-grandstack-part-8/
// https://community.neo4j.com/t/authentication-and-roles-in-grandstack-starter/12510
// https://medium.com/ovrsea/token-authentication-with-react-and-apollo-client-a-detailed-example-a3cc23760e9
// https://blog.carbonfive.com/authorization-and-authentication-in-graphql/
// https://www.apollographql.com/docs/react/api/react/hooks/
// https://neo4j.com/docs/cypher-refcard/current/

const getToken = () => {
    const token = localStorage.getItem('token') || testAccessToken;
    return token ? `Bearer ${token}` : '';
  };

// TODO 1. update the graphqlServerURL for prod deployment
// TODO 2. change the getToken so its not using the dummy one. Is there a firebase token we can use here?

// https://stackoverflow.com/questions/56232820/how-to-update-apolloclient-authorization-header-after-successful-login/56233134
// https://github.com/johnymontana/willow-grandstack/blob/master/web-react/src/index.js
// https://www.freecodecamp.org/news/react-apollo-client-2020-tutorial/

// const graphqlServerURL = process.env.REACT_APP_API_URL || 'http://localhost:4000/';
const graphqlServerURL = process.env.REACT_APP_API_URL || 'https://auth4j-2osgdbfyha-uc.a.run.app'; // TESTING IN PROD

const client = new ApolloClient({
    uri: graphqlServerURL,
    request: (operation) => {
        operation.setContext({
          headers: {
            authorization: getToken(),
          },
        });
      },
    onError: ({ networkError, graphQLErrors }) => {
        console.log('graphQLErrors', graphQLErrors)
        console.log('networkError', networkError)
    }
})

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.getElementById("root")
);