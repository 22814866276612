import { useState, useEffect, useRef } from "react";

export default function useTimer(settings) {
    const { expiryTimestamp: expiry, onExpire } = settings || {};
    const [expiryTimestamp, setExpiryTimestamp] = useState(expiry);
    const [seconds, setSeconds] = useState(Time.getSecondsFromExpiry(expiryTimestamp));
    const [isRunning, setIsRunning] = useState(true);
    const intervalRef = useRef();

    function clearIntervalRef() {
        if (intervalRef.current) {
            setIsRunning(false);
            clearInterval(intervalRef.current);
            intervalRef.current = undefined;
        }
    }

    function handleExpire() {
        clearIntervalRef();
        Validate.onExpire(onExpire) && onExpire();
    }

    function start() {
        if (!intervalRef.current) {
            setIsRunning(true);
            intervalRef.current = setInterval(() => {
                const secondsValue = Time.getSecondsFromExpiry(expiryTimestamp);
                if (secondsValue <= 0) {
                    handleExpire();
                }
                setSeconds(secondsValue);
            }, 1000);
        }
    }

    function pause() {
        clearIntervalRef();
    }

    function resume() {
        if (!intervalRef.current) {
            setIsRunning(true);
            intervalRef.current = setInterval(() => setSeconds((prevSeconds) => {
                const secondsValue = prevSeconds - 1;
                if (secondsValue <= 0) {
                    handleExpire();
                }
                return secondsValue;
            }), 1000);
        }
    }

    function restart(newExpiryTimestamp) {
        clearIntervalRef();
        setExpiryTimestamp(newExpiryTimestamp);
    }

    useEffect(() => {
        if (Validate.expiryTimestamp(expiryTimestamp)) {
            setSeconds(Time.getSecondsFromExpiry(expiryTimestamp));
            start();
        }
        return clearIntervalRef;
    }, [expiryTimestamp]);


    return {
        ...Time.getTimeFromSeconds(seconds), start, pause, resume, restart, isRunning,
    };
}

export class Time {
    // https://github.com/amrlabib/react-timer-hook/blob/master/src/utils/Time.js
    static getTimeFromSeconds(totalSeconds) {
        const days = Math.floor(totalSeconds / (60 * 60 * 24));
        const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        return {
            seconds,
            minutes,
            hours,
            days,
        };
    }

    static getSecondsFromExpiry(expiry) {
        const now = new Date().getTime();
        const milliSecondsDistance = expiry - now;
        if (milliSecondsDistance > 0) {
            return Math.floor(milliSecondsDistance / 1000);
        }
        return 0;
    }

    static getSecondsFromTimeNow() {
        const now = new Date();
        const currentTimestamp = now.getTime();
        const offset = (now.getTimezoneOffset() * 60);
        return (currentTimestamp / 1000) - offset;
    }

    static getFormattedTimeFromSeconds(totalSeconds, format) {
        const { seconds: secondsValue, minutes, hours } = Time.getTimeFromSeconds(totalSeconds);
        let ampm = '';
        let hoursValue = hours;

        if (format === '12-hour') {
            ampm = hours >= 12 ? 'pm' : 'am';
            hoursValue = hours % 12;
        }

        return {
            seconds: secondsValue,
            minutes,
            hours: hoursValue,
            ampm,
        };
    }
}

export class Validate {
    //https://github.com/amrlabib/react-timer-hook/blob/master/src/utils/Validate.js
    static expiryTimestamp(expiryTimestamp) {
        const isValid = (new Date(expiryTimestamp)).getTime() > 0;
        if (!isValid) {
            console.warn('react-timer-hook: { useTimer } Invalid expiryTimestamp settings', expiryTimestamp); // eslint-disable-line
        }
        return isValid;
    }

    static onExpire(onExpire) {
        const isValid = onExpire && typeof onExpire === 'function';
        if (onExpire && !isValid) {
            console.warn('react-timer-hook: { useTimer } Invalid onExpire settings function', onExpire); // eslint-disable-line
        }
        return isValid;
    }
}