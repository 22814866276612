import React from 'react'
import useStickyState from '../Hooks/useStickyState';

export const StoreContext = React.createContext(null)

export default ({ children }) => {

  function hashCode(s) {
    for(var i = 0, h = 0; i < s.length; i++)
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    //console.log(`activity: ${h} --> hashcode: ${s}`);
        return h;
  }

  const [dialogContent, setDialogContent] = React.useState({})
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [sharing, setSharing] = React.useState([])
  const [help, setHelp] = React.useState([])

  const [statuscolorTimecard, setStatuscolorTimecard] = useStickyState('#231f20');
  const [loadedmodal, setLoadedmodal] = useStickyState('', 'loadedmodal' + hashCode('loadedmodal'));
  const [donecustom, setDonecustom] = useStickyState([], 'donecustom');
  const [doneselect, setDoneselect] = useStickyState([], 'doneselect');
  const [doneworkout, setDoneworkout] = useStickyState([], 'doneworkout');
  const [showaddactivity, setShowaddactivity] = useStickyState(false, showaddactivity);
  const [clockedin, setClockedin] =  useStickyState(false, 'clockedin');
  const [sessionid, setSessionid] = useStickyState('', 'sessionid');
  const [tcsessionid, setTcsessionid] = useStickyState('', 'tcsessionid');
  
  const store = {
    share: [sharing, setSharing],
    help: [help, setHelp],
    modaldialog: [loadedmodal, setLoadedmodal],
    mapdialog: [dialogContent, setDialogContent],
    mapdialogopen: [dialogOpen, setDialogOpen],
    activitiescustom: [donecustom, setDonecustom],
    activitiesselected: [doneselect, setDoneselect],
    activitiesclockedin: [clockedin, setClockedin],
    activitiessessionid: [sessionid, setSessionid],
    timecardsessionid: [tcsessionid, setTcsessionid],
    activitiesaddvisible: [showaddactivity, setShowaddactivity],
    timecard: [statuscolorTimecard, setStatuscolorTimecard],
    exercisestore: [doneworkout, setDoneworkout],
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}