import React, { useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { AppBar, Badge, Grid, IconButton, Menu, MenuItem, Typography, Toolbar } from "@material-ui/core";


import MoreIcon from '@material-ui/icons/MoreVert';

import firebase from '../firebase'
import { withRouter } from 'react-router-dom'
import TimeCard from '../TimeCard'
import Homework from '../Homework'
import Workout from '../Workout'

import { StoreContext } from '../Hooks/store';
import { StatusIcon } from '../TimeCard/StatusIcon'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    marginTop: '0px',
  },
  customizeToolbar: {
    maxHeight: '64px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  statusIconSpan: {
    margin: '5px 0 0 10px',
    display: 'inline-block',
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function PrimarySearchAppBar(props) {
  const {
    timecard
  } = React.useContext(StoreContext);
  const [statuscolorTimecard] = timecard;

  const { modaldialog } = React.useContext(StoreContext);
  const [loadedmodal, setLoadedmodal] = modaldialog;

  //const { classes } = props
  const prps = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  //const [homeworkOpen, setHomeworkOpen] = useState(false);


  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const homeworkToggle = () => {
  //   setHomeworkOpen(true);
  // }

  // let homework;
  // if (homeworkOpen) {
  //   homework = <Homework show={true} />
  // } else {
  //   homework = <Homework show={false} />
  // }

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCustomMenuOpen = () => {
    //getLocation() // move this to TimeCard
    handleMenuClose();
  };

  const handleHomeworkMenuOpen = () => {
    setLoadedmodal('Homework');
    handleMenuClose();
  };

  const handleWorkoutMenuOpen = () => {
    setLoadedmodal('Workout');
    handleMenuClose();
  };

  function logPosition(position) {
    //   const now = Date.now();
    //   const location_payload = `{
    //     "client_utc_ts": ${now}, 
    //     "latitude": ${position.coords.latitude}, 
    //     "longitude": ${position.coords.longitude}
    // }`;

    const location_payload = `{
      "Y": ${position.coords.latitude}, 
      "X": ${position.coords.longitude}
  }`;

    const locObj = JSON.parse(location_payload);
    console.log(JSON.stringify(locObj));
    addPosition(locObj);
  }


  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    //logout(prps);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const doLogout = () => {
    logout(prps);
    handleMenuClose();
  };




  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={doLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleCustomMenuOpen}>
        <TimeCard rendercolor='primary' />
      </MenuItem>
      <MenuItem onClick={handleHomeworkMenuOpen}>
        <Homework rendercolor='primary' />
      </MenuItem>
      <MenuItem onClick={handleWorkoutMenuOpen}>
        <Workout rendercolor='primary' />
      </MenuItem>


      {/* <MenuItem onClick={handleHomeworkMenuOpen}>
        <IconButton onClick={handleHomeworkMenuOpen} color="inherit">
          <Badge color="primary">
            <WatchOutlinedIcon onClick={handleHomeworkMenuOpen} />
          </Badge>
        </IconButton>
        <Homework />
        {homework}
      </MenuItem> */}

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  async function logout(props) {
    await firebase.logout()
    props.history.push('/login')
  }

  async function addPosition(locationData) {
    try {
      await firebase.addLocation(locationData)
    } catch (error) {
      console.log('Clock in/out reported error below...');
      console.log(error)
    }
  }

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.customizeToolbar} >
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            geowork.app
          </Typography>
          <span className={classes.statusIconSpan}>
            <StatusIcon cls1={statuscolorTimecard} />
          </span>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>*/}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="show 17 new notifications"
              color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="Time Card"
              onClick={handleCustomMenuOpen}
              color="inherit">
              <TimeCard rendercolor='#fff' />
            </IconButton>
            <IconButton
              aria-label="Homework"
              onClick={handleCustomMenuOpen}
              color="inherit">
              <Homework rendercolor='#fff' />
              {/* <span onClick={homeworkToggle}>home work</span> */}
            </IconButton>
            <IconButton
              aria-label="Workout"
              onClick={handleCustomMenuOpen}
              color="inherit">
              <Workout rendercolor='#fff' />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default withRouter(PrimarySearchAppBar)
