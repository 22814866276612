import React, { useEffect, useState } from 'react'
import { Typography, Paper, Avatar, CircularProgress, Button } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline';
import VerifiedUserOutlined from '@material-ui/icons/VerifiedUserOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import firebase from '../firebase'
import { withRouter } from 'react-router-dom'
import { PrimarySearchAppBar, StickyFooter } from '../Layouts'
import Map from '../Map'
import Notifications from '../Notifications'

import StoreProvider from '../Hooks/store';

// https://codecubed.io/blog/named-export-vs-default-export// 
// import without {} means we are importing a default export
// importing a named export takes the form :
//import { Hello, Goodbye } from './Greetings';

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(3))]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
	},
	avatar: {
		margin: theme.spacing(4),
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		marginTop: theme.spacing(3),
	},
})

function Dashboard(props) {
	const { classes } = props

	//const { modaldialog } = React.useContext(StoreContext);
	//const [loadedmodal, setLoadedmodal] = modaldialog;
	//const showHomework = false; //(loadedmodal === " Homework");
	//alert(loadedmodal);

	if (!firebase.getCurrentUsername()) {
		// not logged in
		alert('Please login first')
		props.history.replace('/login')
		return null
	}

	return (

		<StoreProvider>
			<CssBaseline />
			<PrimarySearchAppBar />
			<Notifications />
			<Map />
			<StickyFooter />
		</StoreProvider>

	)

	async function logout() {
		await firebase.logout()
		props.history.push('/')
	}
}

export default withRouter(withStyles(styles)(Dashboard))