import React, { useEffect } from 'react';
import { Btn, StartBtn, PauseBtn, TimerLbl, InputText, Select } from './ActivityTimer.styled';

import { StoreContext } from '../Hooks/store';
import useStickyState from '../Hooks/useStickyState';

import { hashCode } from '../Utils/hashCode';
import { localTime } from '../Utils/localTime';
import { prettyTime } from '../Utils/prettyTime';

import firebase from '../firebase'
import { notify } from "../Notifications";
import { getDeviceInfo } from '../Utils/getDeviceInfo';

import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

const CREATE_FOO = gql`
  mutation CreateFoo($f: String!, $e: String) {
    CreateFoo(foo: $f, elapsed: $e) {
      _id
      foo
    }
  }
`;

const CREATE_TIMED_ACTIVITY = gql`
    mutation CreateTimedActivity($n: String!, $si: String, $c: Float!, $s: Float, $e: Float, $t: Float, $tz: String, $tzo: Int, $m: Float) {
        CreateTimedActivity(name: $n, sessionId: $si, createTs: $c, startUtc: $s, endUtc: $e, totalSecs: $t, tmZone: $tz, tmZoneOffset: $tzo, modTs: $m) {
            _id
            name
            totalSecs
        }
    }
`;

export default function ActivityTimer(props) {
  const [customtxt, setCustomtxt] = useStickyState('', 'custom' + hashCode('custom' + props.activity));
  const [selectedval, setSelectedval] = useStickyState('', 'selectedval' + hashCode('selectedval' + props.activity));

  const selectOptions = props.options;

  const [time, setTime] = useStickyState(new Date().toLocaleTimeString(), 'time' + hashCode('time' + props.activity));
  const [paused, setPaused] = useStickyState(false, 'paused' + hashCode('paused' + props.activity));
  const [stopped, setStopped] = useStickyState(true, 'stopped' + hashCode('stopped' + props.activity));
  const [elapsed, setElapsed] = useStickyState(0, 'elapsed' + hashCode('elapsed' + props.activity));
  const [label, setLabel] = useStickyState('Start', 'label' + hashCode('label' + props.activity));
  const [elapsedlabel, setElapsedlabel] = useStickyState('00:00:00', 'elapsedlabel' + hashCode('elapsedlabel' + props.activity));
  const [tm1, setTm1] = useStickyState(Date.now(), 'tm1' + hashCode('tm1' + props.activity));
  const [carry, setCarry] = useStickyState(0, 'carry' + hashCode('carry' + props.activity));
  const [pauselabel, setPauselabel] = useStickyState('Pause', 'pauselabel' + hashCode('pauselabel' + props.activity));
  const [pausedisabled, setPausedisabled] = useStickyState(true, 'pausedisabled' + hashCode('pausedisabled' + props.activity));
  //const [sessionid] = useStickyState(hashCode(firebase.getCurrentUserEmail() + '_' + Date.now()), 'sessionid' + hashCode(firebase.getCurrentUserEmail() + '_' + Date.now()));
  
  const {
    activitiescustom,
    activitiesselected,
    activitiesclockedin,
    activitiessessionid,
    activitiesaddvisible } = React.useContext(StoreContext);
  const [donecustom, setDonecustom] = activitiescustom;
  const [doneselect, setDoneselect] = activitiesselected;
  const [clockedin, setClockedin] = activitiesclockedin;
  const [sessionid, setSessionid] = activitiessessionid;
  const [showaddactivity, setShowaddactivity] = activitiesaddvisible;
  //const [showAddChoice, setShowAddChoice] = useState(false)
  const { clockTimer, selectTimer, customTimer } = determineTimer();

  const [createFoo] = useMutation(CREATE_FOO);
  const [createTimedActivity] = useMutation(CREATE_TIMED_ACTIVITY);


  function determineTimer() {
    const clockTimer = props.activity !== 'Custom' && props.activity !== 'Select';
    const selectTimer = props.activity === 'Select' && selectOptions;
    const customTimer = props.activity === 'Custom';
    return { clockTimer, selectTimer, customTimer };
  }

  function formatElapsed(totalSecs) {
    //return new Date(totalSecs * 1000).toISOString().substr(11, 8);
    return prettyTime(totalSecs * 1000);
  }

  function handleStartStop() {
    if (label === 'Start') {
      // we are starting
      if (clockTimer) {
        setClockedin(true)
        setSessionid(hashCode(firebase.getCurrentUserEmail()) + '_' + Date.now())
        //notify(sessionid)
      }
      setCarry(0);
      setTm1(Date.now())
      setLabel('Stop')
      setStopped(false)
      setPaused(false)
      setPausedisabled(false)
    } else {
      // we are stopping
      const startedAt = tm1;
      const rightnow = Date.now();
      setCustomtxt('');
      setLabel('Start')
      setStopped(true)
      setPaused(false)
      setPausedisabled(true)
      setPauselabel('Pause')
      setTm1(rightnow)
      setElapsedlabel('00:00:00')

      if (props.activity === 'Custom') {
        saveToStickyState(customtxt, rightnow);
        //createFoo({ variables: { f: customtxt, e: elapsed } });
      }

      if (props.activity === 'Select') {
        saveToStickyState(selectedval, rightnow);
        setSelectedval('');
      }

      // START-GRAPHQL
      const actv_name = props.activity === 'Custom' ? customtxt : selectedval;
      saveGraph(actv_name, rightnow, startedAt);
      // END-GRAPHQL

      if (clockTimer) {
        setClockedin(false)
        setSessionid('')

        const clockoutItem = makeTempArray('clockout', elapsedlabel, elapsed, tm1, rightnow);
        const completedItems = [...clockoutItem, ...donecustom, ...doneselect];
        const doneCount = completedItems.length;
        const activity_json = {
          count: doneCount,
          completed: completedItems,
          deviceInfo: JSON.stringify(getDeviceInfo()),
        }
        saveActivitiesToFirebase(activity_json);
      }
    }
  }

  function saveGraph(actv_name, rightnow, startedAt) {
    const activity_name = actv_name ? actv_name : sessionid + '_header';
    const tzone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const tzoffset = new Date().getTimezoneOffset()
    const elapsed_secs = elapsed > 0 ? elapsed : 0;

    createTimedActivity({
      variables: {
        n: activity_name,
        si: sessionid,
        c: rightnow,
        s: startedAt,
        e: rightnow,
        t: elapsed_secs,
        tz: tzone,
        tzo: tzoffset,
        m: rightnow
      }
    });
  }

  function saveToStickyState(doneAct, rightnow) {
    const temp = makeTempArray(doneAct, elapsedlabel, elapsed, tm1, rightnow);

    if (props.activity === 'Custom') {
      setDonecustom([...donecustom, ...temp]);
    }

    if (props.activity === 'Select') {
      setDoneselect([...doneselect, ...temp]);
    }
  }

  async function saveActivitiesToFirebase(activity_json) {
    try {
      notify('Saving...')
      await firebase.addActivities(activity_json);
      setDoneselect([]) // clear local storage
      setDonecustom([]) // clear local storage
      notify('Save Completed.')
    } catch (error) {
      notify('Error saving activities.')
      console.log('addActivities reported error below... localstorage should still have completed activities; save to cloud db failed.');
      console.log(error)
    }
  }

  function handlePauseResume() {
    if (pauselabel === 'Pause') {
      const sofar = carry + (Date.now() - tm1) / 1000
      setCarry(sofar)
      setPaused(true)
      setStopped(false)
      setPauselabel('Resume')
    } else {
      setPaused(false)
      setPauselabel('Pause')
      setTm1(Date.now())
    }
  }

  function handleOnChange(e) {
    setCustomtxt(e.target.value);
  }

  function handleOnSelectedChange(e) {
    setSelectedval(e.target.value);
  }

  function toggleAddActivity() {
    setShowaddactivity(!showaddactivity);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {

      const date = new Date()
      setTime(date.toLocaleTimeString());
      if (paused) {
        return;
      }

      if (stopped) {
        return;
      }

      const diff1 = carry + (Date.now() - tm1) / 1000;

      setElapsed(diff1);
      setElapsedlabel(formatElapsed(elapsed));
    }, 1000);
    return () => {
      clearTimeout(timeout);
    }
  }, [time]);


  return (
    <>
      {clockTimer &&
        <>
          <TimerLbl inputColor={props.color}>{props.activity}</TimerLbl>
          <StartBtn inputColor={props.color} onClick={() => handleStartStop()}>{label}</StartBtn>
        </>
      }
      {selectTimer &&
        <>
          <label for="activitydropdown">{props.placeholder}</label><br />
          <Select name="activitydropdown" disabled={!clockedin || label === 'Stop'} onChange={handleOnSelectedChange} value={selectedval}>
            {selectOptions.map((opt) => (
              <option value={opt}>{opt}</option>
            ))}
          </Select>
          {!showaddactivity && <Btn
            disabled={!clockedin || label === 'Stop'}
            inputColor={'green'}
            onClick={() => toggleAddActivity()}>+</Btn>
          }
          <StartBtn disabled={!clockedin || selectedval === undefined || selectedval === '' || selectedval === ""}
            inputColor={props.color}
            onClick={() => handleStartStop()}>{label}
          </StartBtn>
        </>
      }
      {customTimer &&
        <>
          <label for="custombox">One-time activity:</label><br />
          <InputText
            name="custombox"
            maxLength="30"
            disabled={!clockedin || label === 'Stop'}
            value={customtxt === props.placeholder ? '' : customtxt}
            onChange={handleOnChange}
            // placeholder={props.placeholder}
            onMouseEnter={() => {
              if (customtxt === 'Please fill' || customtxt === "Please fill") {
                setCustomtxt('');
              }
            }
            }
          ></InputText>
          <StartBtn
            disabled={
              !clockedin
              || customtxt === undefined
              || customtxt === ''
              || customtxt === ""
              || customtxt === props.placeholder
              || customtxt === 'Please fill'
              || customtxt === "Please fill"}
            inputColor={props.color}
            onClick={() => handleStartStop()}>{label}
          </StartBtn>
        </>
      }

      {label === 'Stop' &&
        <>
          <PauseBtn inputColor={props.color} onClick={() => handlePauseResume()} disabled={!clockedin || pausedisabled}>{pauselabel}</PauseBtn>
          <TimerLbl inputColor={props.color}>{elapsedlabel}</TimerLbl>
        </>
      }
    </>
  )


}

function makeTempArray(doneAct, elapsedlabel, elapsed, tm1, rightnow) {
  const temp = [];
  const newObj = {
    activity: doneAct,
    elapsedlabel: elapsedlabel,
    elapsed: elapsed,
    utc_start: tm1,
    utc_end: rightnow,
    local_start: localTime(tm1),
    local_end: localTime(rightnow),
  };
  temp.push(newObj);
  return temp;
}
