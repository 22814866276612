import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'

const config = {
	apiKey: "AIzaSyAdXwB3LIMpSO3Cr3nQFTYikRD-V8xfRSw",
	authDomain: "mtdt1-265701.firebaseapp.com",
	databaseURL: "https://mtdt1-265701.firebaseio.com",
	projectId: "mtdt1-265701",
	storageBucket: "mtdt1-265701.appspot.com",
	messagingSenderId: "51933645972",
	appId: "1:51933645972:web:141063dbb038eb42aab881"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
		this.db = app.firestore()
		this.fbListener = this.auth.onAuthStateChanged((user) => {
			if (user) {
				//console.log('user logged in: ', user);
				console.log(`user logged in: ${user.displayName}, ${user.email}`);
			} else {
				console.log('user logged out!');
				//browserHistory.push('/signin');
			}
		});
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	logout() {
		return this.auth.signOut()
	}

	async register(name, email, password) {
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		})
	}

	addQuote(quote) {
		if (!this.auth.currentUser) {
			return alert('Not authorized')
		}

		console.log(' in addQuote about to add the following quote to db...');
		console.log(quote);
		return this.db.doc(`users_fave_quote/${this.auth.currentUser.uid}`).set({
			quote
		});
	}

	addTimeCardEntry(timeCardData) {
		if (!this.auth.currentUser) {
			return alert('Not authorized')
		}

		const utc_now = Date.now();
		const yyyy_dd_mm = new Date().toJSON().slice(0, 10).replace(/-/g, '_');
		const stub = `${yyyy_dd_mm}_${utc_now}`;

		//console.log(timeCardData);

		//https://firebase.googleblog.com/2019/11/cloud-firestore-now-supports-in-queries.html

		// return this.db
		// 	.doc(`crew_time_card/${this.auth.currentUser.uid}/tc_entries/${stub}`).set(timeCardData).then(function () {
		// 		console.log("TimeCard Document successfully written!");
		// 	}).catch(function (error) {
		// 		console.error("Error writing TimeCard document: ", error);
		// 	});

		// Add a new document in collection "crew_time_card"
		let adb = this.db.collection("crew_time_card").doc(`${this.auth.currentUser.uid}_${stub}`).set({
			user_id: `${this.auth.currentUser.uid}`,
			event_name: timeCardData.event_name,
			event_duration_ms: timeCardData.event_duration_ms,
			event_date: timeCardData.event_date,
			utc_start: timeCardData.utc_start,
			utc_end: timeCardData.utc_end,
			utc_saved: Date.now(),
			latitude: timeCardData.latitude, 
			longitude: timeCardData.longitude
			//,geolocation: this.db.GeoPoint(timeCardData.latitude, timeCardData.longitude) //new firebaseAdmin.firestore.GeoPoint(latitude,longitude)
		}).then(function () {
			console.log("crew_time_card~ Document successfully written!");
		}).catch(function (error) {
			alert("Error saving time card: ", error);
		});

		return adb;

		// return this.db.doc(`crew_time_card/${this.auth.currentUser.uid}_${utc_now}`).set({
		// 	timeCardData
		// });
		// return this.db
		// 	.doc(`crew_time_card/${this.auth.currentUser.uid}_tc_entries/${stub}`).set(timeCardData).then(function () {
		// 		console.log("TimeCard Document successfully written!");
		// 	}).catch(function (error) {
		// 		console.error("Error writing TimeCard document: ", error);
		// 	});
	}

	async getTimeCardEntriesForLast24Hours() {
		console.log('in getTimeCardEntriesForLast24Hours');
		if (!this.auth.currentUser) {
			return alert('Not authorized')
		}

		const now = Date.now();
		const oneday = 60 * 60 * 24 * 1000;
		const timediff = now - oneday;
		const jana = new Date(timediff).getTime();

		console.log(`now: ${now}, jana: ${jana}`);
		console.log('in getTimeCardEntriesForLast24Hours');

		// https://sebhastian.com/react-firestore
		// https://github.com/nsebhastian/react-firestore-example/blob/master/src/component/Register.js
		let tc_data = await this.db.collection("crew_time_card")
			.where("utc_saved", ">", jana)
			.where("utc_saved", "<", now)
			.where("user_id", "==", `${this.auth.currentUser.uid}`)
			.orderBy("utc_saved", "asc")
			.get()
			.then(querySnapshot => {
				let data = querySnapshot.docs.map(doc => doc.data());
				//console.log(data); // array of objects
				return data;
			})
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		return tc_data;
	}

	async getTimeCardEntriesForDate(now) {
		console.log('in getTimeCardEntriesForDate');
		if (!this.auth.currentUser) {
			return alert('Not authorized')
		}

		const oneday = 60 * 60 * 24 * 1000;
		const onedayback = now - oneday;
		const yesterday = new Date(onedayback).getTime();
		//console.log('in getTimeCardEntriesForDate');
		console.log(`now: ${now}, yesterday: ${yesterday}`);

		// https://sebhastian.com/react-firestore
		// https://github.com/nsebhastian/react-firestore-example/blob/master/src/component/Register.js
		let tc_data = await this.db.collection("crew_time_card")
			.where("utc_saved", ">=", yesterday)
			.where("utc_saved", "<=", now)
			.where("user_id", "==", `${this.auth.currentUser.uid}`)
			.orderBy("utc_saved", "asc")
			.get()
			.then(querySnapshot => {
				let data = querySnapshot.docs.map(doc => doc.data());
				//console.log(data); // array of objects
				return data;
			})
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		return tc_data;
	}

	addActivities(activityData) {
		if (!this.auth.currentUser) {
			return alert('Not authorized')
		}

		const utc_now = Date.now();
		const yyyy_dd_mm = new Date().toJSON().slice(0, 10).replace(/-/g, '_');
		const stub = `${utc_now}_${yyyy_dd_mm}`;

		// Add a new document in collection "activities"
		let adb = this.db.collection("activities").doc(`${this.auth.currentUser.uid}_${stub}`).set({
			activity_count: activityData.count,
			activity_data: JSON.stringify(activityData.completed),
			deviceInfo: JSON.stringify(activityData.deviceInfo),
			user_id: `${this.auth.currentUser.uid}`,
			utc_saved: Date.now(),
		}).then(function () {
			console.log("activities~ Document successfully written!");
		}).catch(function (error) {
			alert("Error saving activities: ", error);
		});

		return adb;
	}

	addLocation(locationData) {
		if (!this.auth.currentUser) {
			return alert('Not authorized')
		}

		// return this.db.doc(`crew_work_loc/${this.auth.currentUser.uid}`).set({
		// 	locationData
		// }, { merge: true });

		const utc_now = Date.now();
		const newObj = `{
			foo_${utc_now}: ${JSON.stringify(locationData)}
		}`;

		// this.db.doc(`crew_work_loc/${this.auth.currentUser.uid}`).set({
		// 	newObj
		// }, { merge: true }).then(function () {
		// 	console.log("Document successfully written!");
		// }).catch(function (error) {
		// 	console.error("Error writing document: ", error);
		// });

		// let docuRef = this.db.doc(`crew_work_loc/${this.auth.currentUser.uid}`);
		// docuRef.update({ 
		// 	newObj, utc_now
		// }).then(function () {
		// 	console.log("Document successfully written!");
		// }).catch(function (error) {
		// 	console.error("Error writing document: ", error);
		// });

		// TODO https://medium.com/firebase-tips-tricks/how-to-list-all-subcollections-of-a-cloud-firestore-document-17f2bb80a166
		// TODO https://youtu.be/v_hR4K4auoQ
		// return this.db
		// 	.doc(`crew_work_loc/${this.auth.currentUser.uid}`)
		// 	.collection('locations')
		// 	.add(locationData).then(function () {
		// 		console.log("Document successfully written!");
		// 	}).catch(function (error) {
		// 		console.error("Error writing document: ", error);
		// 	});

		return this.db
			.doc(`crew_work_loc/${this.auth.currentUser.uid}/locations/${utc_now}`).set(locationData).then(function () {
				console.log("Document successfully written!");
			}).catch(function (error) {
				console.error("Error writing document: ", error);
			});
	}

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
	}

	// getCurrentUid() {
	// 	return this.auth.uid; // dont do this on the clienside.  can be spoofed.
	// }

	forceRefreshUserToken() {
		this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
			//console.log(idToken);
		}).catch(function (error) {
			console.log(`unable to get token.  error: ${error.message}`);
		});
	}

	getCurrentUsername() {
		return this.auth.currentUser && this.auth.currentUser.displayName
	}

	getCurrentUserEmail() {
		return this.auth.currentUser && this.auth.currentUser.email
	}

	/* Default Firestore rules
	rules_version = '2';
	service cloud.firestore {
	  match /databases/{database}/documents {
		match /{document=**} {
		  allow read, write: if false;
		}
	  }
	}
	 */

	async getCurrentUserQuote() {
		const quote = await this.db.doc(`users_fave_quote/${this.auth.currentUser.uid}`).get()
		return quote.get('quote')
	}
}

export default new Firebase()